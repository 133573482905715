globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"439e98d952817399dc0519d0a83f16ba894057bb"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/capacitor'
import { config } from '@/config'
import { debugIntegration } from '@sentry/react'
const packageJson = require('../../package.json')

const sentryOptions = {
  dsn: 'https://2b2c9947a30b4df79e3346d26f1050c3@o268566.ingest.sentry.io/5946968',
  tracesSampleRate: 1.0,
  release: packageJson.version,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  environment: config.environment,
  dist: process.env.CI_BUILD_NUMBER ?? undefined,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [
    'localhost',
    /^capacitor:\/\//,
    'capacitor://localhost',
    'http://localhost:*',
    'https://localhost:*',
    'ionic://localhost',
    process?.env?.NEXT_PUBLIC_DOMAIN,
  ],
  beforeSend(event) {
    //ignore event ChunkLoadError error
    if (
      event?.exception?.values?.some(
        ({ value, type }) =>
          value === `Unexpected token '<'` || type === 'ChunkLoadError'
      )
    ) {
      return null
    }
    //ignore event FirebaseError auth/popup-blocked error
    if (
      event?.exception?.values?.some(
        ({ value, type }) =>
          value === `Firebase: Error (auth/popup-blocked).` &&
          type === 'FirebaseError'
      )
    ) {
      return null
    }
    //ignore event FirebaseError auth/network-request-failed error
    if (
      event?.exception?.values?.some(
        ({ value, type }) =>
          value === `Firebase: Error (auth/network-request-failed).` &&
          type === 'FirebaseError'
      )
    ) {
      return null
    }
    return event
  },
}

if (process.env.NODE_ENV !== 'production') {
  sentryOptions.integrations = [debugIntegration({ debugger: false })]
}

Sentry.init(sentryOptions)
Sentry.setTag('application', packageJson?.name)
Sentry.setTag('applicationKey', config?.applicationKey)
