import { FieldFunctionOptions, gql } from '@apollo/client'
import { Reference } from '@apollo/client/utilities'

import { UserGachaTicketConnectionFragment } from '@/clients/utoniq-core/schema'

export function purchaseGachaTicketByPoint() {
  return {
    merge(
      existing: Reference,
      incoming: { gachaTicketId: string; count: string },
      { cache, variables }: FieldFunctionOptions
    ) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          prepaidPointBalance(existingPrepaidPointBalance = { balance: 0 }) {
            return {
              ...existingPrepaidPointBalance,
              balance:
                existingPrepaidPointBalance.balance - variables?.inputs?.point,
            }
          },
        },
      })
      const userData = cache.readQuery<{ user: { id: string } }>({
        query: gql`
          query {
            user {
              id
            }
          }
        `,
      })
      if (!userData) {
        return null
      }
      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          gachaTicket(gachaTicket: UserGachaTicketConnectionFragment) {
            return {
              ...gachaTicket,
              __typename: 'UserGachaTicket',
              available: (gachaTicket?.available ?? 0) + incoming.count,
            }
          },
        },
      })
      return null
    },
  }
}
