"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'デジリアトレカ',
    organizationId: 'LLa5moRAgALXCuI2glbU',
    version: '1.7',
    ionicAppId: '1424e533',
    applicationKey: 'digirea',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'jp.digirea',
        appId: '1573205494',
    },
    android: {
        bundleId: 'jp.digirea',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: true,
            trade: true,
            membership: true,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: true,
            liveStream: true,
            item: true,
            store: true,
            campaign: true,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: ['home', 'myCollections', 'campaign-events', 'artist'],
        artistTabs: ['home', 'collections', 'gachas', 'trade', 'threads'],
    },
    deeplinkUrl: 'https://app.digirea.jp',
    helpUrl: 'https://help.utoniq.com/ja/collections/3161857',
    storagePath: 'digirea',
    dynamicLinkPrefix: 'digirea',
};
exports.default = appConfig;
