import { ApolloError } from '@apollo/client'
import { AuthError } from 'firebase/auth'
import { StringMap, TOptions } from 'i18next'
import {
  createContext,
  createRef,
  ReactNode,
  useContext,
  useImperativeHandle,
  useState,
} from 'react'
import type { Namespace, TFunction } from 'react-i18next'
import { Snackbar, SnackbarProps } from 'utoniq-ui'

import { useTranslation } from '@/core'
import { captureError } from '@/core/errors'
import {
  ethers,
  ethRpc,
  firebase as firebaseErrors,
  firebaseCapacitorAuth,
} from '@/core/i18n/locales/ja'

type MessageContext = {
  showError: (
    e: Error | string,
    options?: StringMap,
    action?: SnackbarProps['action'],
    secondaryAction?: SnackbarProps['secondaryAction'],
    secondaryMessage?: SnackbarProps['secondaryAction']
  ) => number
  showMessage: (
    message: string,
    severity?: SnackbarProps['severity'],
    action?: SnackbarProps['action'],
    secondaryAction?: SnackbarProps['secondaryAction'],
    autoHide?: boolean | undefined,
    secondaryMessage?: SnackbarProps['secondaryAction']
  ) => number
  handleClose: () => void
}

const Context = createContext({} as MessageContext)

/**
 * @description use for handle show error/message outside component
 *
 * @example
 *
 * messageRef.current?.showError()
 * messageRef.current?.showMessage()
 */
export const messageRef = createRef<MessageContext>()

export function useMessage() {
  return useContext(Context)
}

let uniqueId = 1

type MessageProps = {
  id: number
  message: string
  secondaryMessage?: SnackbarProps['secondaryAction']
  open: boolean
  severity: SnackbarProps['severity']
  action?: SnackbarProps['action']
  secondaryAction?: SnackbarProps['secondaryAction']
  autoHide?: boolean
}
export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('firebase')

  const [{ current, queue }, setState] = useState<{
    current: MessageProps | null
    queue: MessageProps[]
  }>({
    current: null,
    queue: [],
  })

  useImperativeHandle(messageRef, () => ({
    showError,
    showMessage,
    handleClose,
  }))

  const showError = (
    e: Error | string,
    options?: StringMap,
    action?: SnackbarProps['action'],
    secondaryAction?: SnackbarProps['secondaryAction'],
    secondaryMessage?: SnackbarProps['secondaryAction']
  ) => {
    const message = convertErrorMessage(t, e, options)

    const id = uniqueId++
    const snack: MessageProps = {
      id,
      message,
      open: true,
      severity: 'error',
      action,
      secondaryAction,
      secondaryMessage,
    }

    setState(({ current, queue }) =>
      current
        ? { current, queue: queue.concat(snack) }
        : { current: snack, queue }
    )

    return id
  }

  const showMessage = (
    message: string,
    severity: SnackbarProps['severity'] = 'success',
    action?: SnackbarProps['action'],
    secondaryAction?: SnackbarProps['secondaryAction'],
    autoHide?: boolean
  ) => {
    const id = uniqueId++
    const snack: MessageProps = {
      id,
      message,
      open: true,
      severity,
      action,
      secondaryAction,
      autoHide,
    }

    setState(({ current, queue }) =>
      current
        ? { current, queue: queue.concat(snack) }
        : { current: snack, queue }
    )

    return id
  }

  const handleClose = () => {
    setState(currentState => ({
      ...currentState,
      current: { ...(currentState.current as MessageProps), open: false },
    }))
    // time to snack close animation
    setTimeout(openNext, 1000)
  }

  const openNext = () => {
    setState(({ current, queue }) =>
      queue.length
        ? { current: queue[0], queue: queue.slice(1) }
        : { current: null, queue: [] }
    )
  }

  return (
    <Context.Provider value={{ showError, showMessage, handleClose }}>
      {current && (
        <Snackbar
          key={current.id}
          autoHide
          {...current}
          onClose={handleClose}
        />
      )}
      {children}
    </Context.Provider>
  )
}

/**
 * @description
 * エラーオブジェクトに応じたエラーメッセージをi18n対応して返す。
 * @returns {string} message
 */
function convertErrorMessage(
  t: TFunction<Namespace<string>>,
  e: Error | AuthError | ApolloError | string,
  options?: TOptions<StringMap>
) {
  if (typeof e === 'string') return e

  /**
   * @todo
   * fix type error after upgrade firebaseSDK v9
   * https://github.com/firebase/firebase-admin-node/issues/403#issuecomment-894052764
   */

  /**
   * @description
   * クレジットカードに関するエラー
   */
  if (
    'graphQLErrors' in e &&
    e.graphQLErrors &&
    e?.graphQLErrors?.[0]?.extensions?.exception?.errors?.[0]?.rawType ===
      'card_error'
  ) {
    return t('cardError')
  }

  /**
   * @description
   *
   * utoniq-coreのgraphQLErrorの場合、userMessageを返す。
   */
  if (
    'graphQLErrors' in e &&
    e.graphQLErrors &&
    e.graphQLErrors?.[0]?.extensions?.userMessage
  ) {
    return e.graphQLErrors[0].extensions.userMessage
  }

  if ('code' in e && e.code) {
    if (Object.keys(firebaseErrors.auth.errors).includes(e?.code))
      return t(`firebase:auth.errors.${e.code}`, options)

    /**
     * @description
     * metamask rpcエラーをそのまま受け取る場合は、codeをエラーオブジェクトのプロパティで提供してくれているのでそのまま出力
     * https://docs.metamask.io/guide/ethereum-provider.html#events
     */
    if (Object.keys(ethRpc.errors).includes(String(e.code))) {
      captureError(e, 'useMessageEthRpc')
      return t(`ethRpc:errors.${e.code}`, options)
    }
  }

  /**
   * @description
   * ethersのエラーでラップされるとプロパティでエラーコードを提供してくれていないので、e.messageから対象エラーコードを判定して出力
   * https://github.com/ethers-io/ethers.js/blob/26cdbab59bb68185355f376cdcd32a3577ffd44d/packages/logger/src.ts/index.ts
   */
  const ethersError = Object.keys(ethers.errors).find(errorCode =>
    e.message.match(new RegExp(errorCode, 'g'))
  )
  if (ethersError) {
    captureError(e, 'useMessageEthers')
    return t(`ethers:errors.${ethersError}`, options)
  }

  /**
   * @description
   * firebase-capacitor-authによるネイティブの認証エラー
   * https://github.com/baumblatt/capacitor-firebase-auth/blob/master/ios/Plugin/Handlers/TwitterProviderHandler.swift
   * https://github.com/baumblatt/capacitor-firebase-auth/blob/master/android/src/main/java/com/baumblatt/capacitor/firebase/auth/handlers/TwitterProviderHandler.java
   */
  if (Object.keys(firebaseCapacitorAuth.errors).includes(String(e.message))) {
    return t(`firebaseCapacitorAuth:errors.${e.message}`, options)
  }

  return e.message
}
